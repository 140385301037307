<script>
import Vue from "vue";

export default {
  data() {
    return {
      currentQueries: window.location.href.includes("?")
        ? window.location.href.substring(window.location.href.indexOf("?") + 1)
        : "",
      apiKey: "7f7e05024cf424a93a7da7acb0020e12de6dd891",
      defaultRedirectUri: "/login",
    };
  },
  created() {
    this.setCache();
  },
  methods: {
    setCache() {
      const pairsInString = this.currentQueries.split("&");
      let hasCorrectApiKey = false;
      let redirectUri = this.defaultRedirectUri;

      const pairs = pairsInString.reduce((arr, elem) => {
        const p = elem.split("=");
        if (p[0] === "apiKey") {
          hasCorrectApiKey = p[1] === this.apiKey;
        } else if (p[0] === "redirectUri") {
          redirectUri = p[1];
        } else {
          arr.push({ key: p[0], value: p[1] });
        }
        return arr;
      }, []);

      if (hasCorrectApiKey) {
        pairs.forEach((elem) => this.setCookie(elem.key, elem.value));
      }

      window.location = decodeURIComponent(redirectUri);
    },

    setCookie(key, value) {
      Vue.$cookies.set(key, value);
    },
  },
};
</script>

<style>
.bg-main {
  display: none;
}
</style>
